import { EAppId } from '@timeedit/types/lib/enums';
import { EAppEnv } from '@timeedit/types/lib/enums/appEnv.enum';
import { ITEToken, TOrganization } from '@timeedit/types/lib/types';
import { ProductSelector } from '@timeedit/ui-components/lib/src/components/ProductSelector/ProductSelector';
import { angularize } from 'react-in-angularjs';

type TEProductSelectorProps = {
  appEnv: EAppEnv;
  organization: TOrganization,
  currentProduct: EAppId;
  user: ITEToken;
}

const TEProductSelector = ({ appEnv, organization, currentProduct, user }: Readonly<TEProductSelectorProps>) =>  {
  return organization && user ? (
    <div className='product-selector'>
      <ProductSelector
        appEnv={appEnv}
        organization={organization}
        currentProduct={currentProduct}
        user={user}
      />
    </div>
  ) : null;
};

angularize(TEProductSelector, 'productSelector', angular.module('diceApp'), {
  appEnv: '@',
  organization: '<',
  currentProduct: '@',
  user: '<'
});

export default TEProductSelector;
